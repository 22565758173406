const { createSlice } = require("@reduxjs/toolkit");
 
const initialState = {
    privileges: {},
};
 
const PrivilegesSlice = createSlice({
    name: 'privileges',
    initialState,
    reducers: {
        setPrivileges: (state, action) => {
            state.privileges = action.payload;
        },
    },
});
 
export const { setPrivileges } = PrivilegesSlice.actions;
export default PrivilegesSlice.reducer;