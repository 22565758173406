import { getCompanyId } from "services/identity.service";
import { commonTransform } from "../handleTransformation";
import { GET_POSITIONS_URL, UPDATE_POSITION_JOURNEY_URL } from "services/url.service";
import { tags } from "../constants";

export const positionMutations = {
  updatePositionJourney: {
    getUrl: ({ companyId }) => UPDATE_POSITION_JOURNEY_URL(companyId),
    method: "PUT",
    transformResponse: (res) => commonTransform(res),
    invalidatesTags: [tags.POSITION],
  },
};

export const positionQueries = {
  getPositions : {
    getUrl: ({ search, offset, limit, sort, filters, companyId } ) => {
        return GET_POSITIONS_URL(companyId, search, offset, limit, sort, filters)
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
    providesTags: [tags.POSITION],
  },
};
